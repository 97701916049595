import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../../styles/Workflow.css';
import { textColor , buttonColor, workflowNodeStyle } from '../../constants/Workflow';
import { Typography , Button, TextField, Autocomplete } from '@mui/material';
import Swal from 'sweetalert2';
import { NoDataPage } from './NoData';
import { createWorkflowPOST, getWorkflows, updateWorkflowPOST } from '../../services/Endpoints';
import AlertComponent from './AlertComponent';
import { PreLoader } from './preloader';
import { ReactFlow , Background , Controls, applyNodeChanges, applyEdgeChanges, addEdge , Edge, Connection } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { AnimatedSVGEdge } from './AnimatedNode';
import { autoCompleteStyle, textStyle } from '../../constants/AnalyticsPageConst';

export const Workflow = () => {
    const [preloader, setPreloader] = useState<boolean | null>(true);
    const [workflowsData, setWorkflowsData] = useState<any>({});
    const [currentWorkflow , setCurrentWorkflow] = useState<string | null>(null);
    const [keyMessage, setKeyMessage] = useState<string>('');
    const [errorType, setErrorType] = useState<string>('');
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
    const location = useLocation();
    const { demoDataset } = location?.state;
    const [nodes, setNodes] = useState<any>([]);
    const [edges, setEdges] = useState<Edge[]>([]);
    const onNodesChange = useCallback(
        (changes) => setNodes((nds:any) => applyNodeChanges(changes, nds)),
        []
      );
    const onEdgesChange = useCallback(
        (changes) => setEdges((eds:any) => applyEdgeChanges(changes, eds)),
        [],
      );
    const onConnect = useCallback(
        (params: Connection | Edge) => {
          if (params.source && params.target) {
            const newEdge = {
                ...params,
                style: { stroke: '#f79009', strokeWidth: 1 },
                animated: true
            }
            setEdges((eds: Edge[]) => addEdge(newEdge, eds));
          } else {
            console.error("Invalid connection: Missing source or target handle");
          }
        },
        [setEdges]
    );

    useEffect(() => {
        if(currentWorkflow !== null) 
            {
                if(checkCurrentWorkflowIndex() !== -1){
                    setNodes(workflowsData.workflows[checkCurrentWorkflowIndex()].workflow_sequence.nodes);
                    setEdges(workflowsData.workflows[checkCurrentWorkflowIndex()].workflow_sequence.edges);
                }
            }
    },[currentWorkflow , workflowsData.workflows])

    useEffect(() => {
        const getWorkflowData = async () => {
            try{
                const response = await getWorkflows();
                const data = await response.json();
                setWorkflowsData(data);
                data.workflows.length >0 && setCurrentWorkflow(data.workflows[0].workflow_name)
                setPreloader(false);
            }
            catch(err:any)
            {
                setWorkflowsData({});
                setKeyMessage(err.message)
                setErrorType('error');
                setOpenSnackbar(true);
                setPreloader(false);
            }
        }
        getWorkflowData();
    },[])

    const checkCurrentWorkflowIndex = () => {
        return workflowsData.workflows.findIndex((data:any) => data.workflow_name === currentWorkflow)
    }

    const updateWorkflow = async () => {
        const payload = JSON.stringify({
            workflow_name: workflowsData.workflows[checkCurrentWorkflowIndex()].workflow_name,
            workflow_id: workflowsData.workflows[checkCurrentWorkflowIndex()].id,
            workflow_sequence: {
               nodes : nodes,
               edges : edges
            }
        });
        try{
            const response = await updateWorkflowPOST(payload,'PUT');
            const data = await response.json();
            setKeyMessage(`Workflow updated successfully , ${data.updatedWorkflow.workflow_name}`)
            setErrorType('success');
            setOpenSnackbar(true);
        }
        catch(err:any)
        {
            setKeyMessage(err.message)
            setErrorType('error');
            setOpenSnackbar(true);
        }
    }

    const deleteWorkflow = async () => {
        Swal.fire({
            title: "Delete Workflow",
            background: 'black',
            color: 'white',
            text : `Do you want to delete the workflow ?`,
            showConfirmButton: true,
            confirmButtonText:'Yes',
            confirmButtonColor:'#f79009',
            showCancelButton: true,
            cancelButtonText:'No',
            showCloseButton: true,
          })
          .then(async (result) => {
            if(result.isConfirmed)
                {
                    try{
                        const response = await updateWorkflowPOST(JSON.stringify({
                            workflow_id : workflowsData.workflows[checkCurrentWorkflowIndex()].id
                        }),'DELETE')
                        const data = await response.json();
                        setKeyMessage(`Workflow deleted successfully , ${data.deletedWorkflow.workflow_name}`)
                        setErrorType('success');
                        setOpenSnackbar(true);
                        setTimeout(() => {
                            window.location.reload();
                        },2000)
                    }
                    catch(err:any)
                    {
                        setKeyMessage(err.message)
                        setErrorType('error');
                        setOpenSnackbar(true);
                    }
                }
          })
    } 

    const workflowActionTrigger = (e:any,selectedNode:any) => {
        !demoDataset && Swal.fire({
            title: "Workflow Action",
            background: 'black',
            color: 'white',
            html: `
              <div class="handleScoreDetailsDiv">
                <select id="taskType" name="taskType">
                    <option value="deleteNode">Delete Node</option>
                    <option value="update">Update Node</option>
                </select>
                <input class="handleScoreInput" type="text" id="updateWorkflowInput" placeholder="Enter Node Name">
              </div>
            `,
            showConfirmButton: true,
            confirmButtonText: 'Submit',
            confirmButtonColor: '#f79009',
            showCancelButton: true,
            showCloseButton: true,
            didOpen: () => {
              const workflowActionInputButton = document.getElementById('taskType') as HTMLSelectElement;
              const updateWorkflowInput = document.getElementById('updateWorkflowInput') as HTMLInputElement;
              workflowActionInputButton.addEventListener('change' , (e:any) => {
                if(e.target.value === 'update')
                {
                    updateWorkflowInput.style.display='block';
                }
                if(e.target.value.includes('delete'))
                {
                    updateWorkflowInput.style.display='none';
                }

              })
              
            }
          })
            .then(async (result: any) => {
                const workflowActionInputButton = (document.getElementById('taskType') as HTMLSelectElement).value;
                const updateWorkflowInput = (document.getElementById('updateWorkflowInput') as HTMLInputElement).value;
                if (result.isConfirmed) {
                    if(workflowActionInputButton === 'update')
                    {
                        setNodes((nds:any) =>
                            nds.map((node:any) =>
                              node.id === selectedNode.id
                                ? { ...node, data: { label: updateWorkflowInput } }
                                : node
                            )
                        )
                        
                    }
                    else if(workflowActionInputButton === 'deleteNode')
                    {
                        setNodes((nds:any) => nds.filter((node:any) => 
                            node.id !== selectedNode.id));
                    }
                }
            })
    }

    const addWorkflowNode = () => {
        !demoDataset && Swal.fire({
            title: "Add Node",
            background: 'black',
            color: 'white',
            html: `
              <div class="handleScoreDetailsDiv">
                <input class="handleScoreInput" type="text" id="WorkflowInput" placeholder="Enter node details">
              </div>
            `,
            showConfirmButton: true,
            confirmButtonText: 'Submit',
            confirmButtonColor: '#f79009',
            showCancelButton: true,
            showCloseButton: true,
            didOpen: () => {
            }
          })
          .then(async (result) => {
            const workflowInput = (document.getElementById('WorkflowInput') as HTMLInputElement).value;
            if(result.isConfirmed)
            {
                const newNode = {
                    id: (nodes.length + 1).toString(),
                    data: { label: workflowInput },
                    position: { x: Math.random() * 400, y: Math.random() * 400 },
                    style: workflowNodeStyle
                };
                setNodes((nds: any) => [...nds, newNode]);
            }
          }) 
    }

    const createWorkflow = () => {
        !demoDataset &&
          Swal.fire({
            title: "Create Workflow",
            background: "black",
            color: "white",
            html: `
                    <div class="handleScoreDetailsDiv">
                      <input class="handleScoreInput" type="text" id="workflowName" placeholder="Enter Workflow Name">
                      <div id="workflowInputs">
                        <label>Node</label>
                        <input class="handleScoreInput" type="text" name="workflowInput" placeholder="Enter Node">
                      </div>
                      <button type="button" id="addWorkflowInput" style="margin-top: 10px; background-color: #f79009; color: white; border: none; padding: 5px 10px; cursor: pointer;">
                        Add Another Node
                      </button>
                    </div>
                  `,
            showConfirmButton: true,
            confirmButtonText: "Add Workflow",
            confirmButtonColor: "#f79009",
            showCancelButton: true,
            showCloseButton: true,
            didOpen: () => {
              const addWorkflowInputButton = document.getElementById("addWorkflowInput") as HTMLButtonElement;
              addWorkflowInputButton.addEventListener("click", () => {
                const workflowInputsDiv = document.getElementById("workflowInputs") as HTMLDivElement;
                const newInput = document.createElement("input");
                newInput.type = "text";
                newInput.name = "workflowInput";
                newInput.placeholder = "Enter Node";
                newInput.classList.add("handleScoreInput");
                newInput.style.marginTop = "30px";
                workflowInputsDiv.appendChild(newInput);
              });
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              const workflowName = (document.getElementById("workflowName") as HTMLInputElement).value;
              const workflowInputs = Array.from(document.getElementsByName("workflowInput")) as HTMLInputElement[];
      
              const validInputs = workflowInputs.filter((input) => input.value.trim() !== "");
      
              if (validInputs.length === 0) {
                alert("Add all fields to create workflow");
                return;
              }

            const workflows = validInputs.map((input, index) => {
                let nodeType = '';
                if (index === 0) {
                nodeType = 'input';
                } else if (index === validInputs.length - 1) {
                nodeType = 'output';
                }
            
                return {
                id: (index + 1).toString(),
                data: { label: input.value },
                position: { x: (index * 200) + 100, y: 100 },
                type: nodeType,
                style: workflowNodeStyle,
                };
            });
            
            const edges = validInputs.map((input, index) => {
                if (index < validInputs.length - 1) {
                return {
                    id: `e${index + 1}-${index + 2}`,
                    source: (index + 1).toString(),
                    target: (index + 2).toString(),
                    type: "smoothstep",
                    animated: true,
                    style: { stroke: '#f79009', strokeWidth: 1 }, 
                };
                }
                return null;
            }).filter((edge) => edge !== null);
      
              const payload = JSON.stringify({
                workflow_name: workflowName,
                workflow_sequence: {
                  nodes: workflows,
                  edges: edges,
                },
              });
      
              try {
                const response = await createWorkflowPOST(payload, "POST");
                const data = await response.json();
                setKeyMessage(`New Workflow created, ${data.newWorkflow.workflow_name}`);
                setErrorType("success");
                setOpenSnackbar(true);
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } catch (err: any) {
                setKeyMessage(err.message);
                setErrorType("error");
                setOpenSnackbar(true);
              }
            }
          });
      };      

    const upgrade = () => {
        Swal.fire({
            title: "Upgrade",
            background: 'black',
            color: 'white',
            html: `
              <div class="premiumSubscribeLinkDiv">
                <h6> Currently , the workflow management is in beta mode . To upgrade reach us out to <a href="mailto:support@cortalinsight.com?subject=Workflow Management" class="linkStyle" target="_blank">support@cortalinsight.com</a></h6>
              </div>
            `,
            text : ` `,
            showConfirmButton: false,
            confirmButtonColor:'#f79009',
            showCancelButton: false,
            showCloseButton: true,
          })
    }
    
    return(
        <>
            {preloader ? (<><PreLoader preloaderCheck={preloader} /></>) : (
                <div className='workflow'>
                    <div className='workflowHeader'>
                        <Typography variant='h5' sx={textColor}>Workflow Management - Beta</Typography>
                        {demoDataset && <Button className='primary' onClick={upgrade} sx={buttonColor}>Upgrade</Button>}
                    </div>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                        <div style={{display:'flex',flexDirection:'column',gap:'20px',padding:'30px'}}>
                            <Typography variant='h5' sx={textColor}>Select Workflow</Typography>
                            <Autocomplete
                                selectOnFocus
                                id="select-on-focus"
                                options={Array.from(workflowsData.workflows).map((data:any) => data.workflow_name)}
                                sx={autoCompleteStyle}
                                value={currentWorkflow}
                                onChange={(e, newValue) => { setCurrentWorkflow(String(newValue === null ? workflowsData.workflows[0].workflow_name : newValue)) }}
                                renderInput={(params) => <TextField {...params} sx={textStyle} label="Workflow" />}
                            />
                        </div>
                        {!demoDataset && (
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', padding: '30px' }}>
                                <Button className='primary' onClick={createWorkflow} sx={buttonColor}>Create New Workflow </Button>
                                <Button className='primary' onClick={deleteWorkflow} sx={buttonColor}>Delete Workflow </Button>
                                <Button className='primary' onClick={(e) => { addWorkflowNode() }} sx={buttonColor}>Add Node</Button>
                                <Button className='primary' onClick={updateWorkflow} sx={buttonColor}>Save Workflow</Button>
                            </div>
                        )} 
                    </div>
                   
                    {(nodes.length > 0) ? (
                        <div style={{ width: '100%', height: '500px' }}>
                        <ReactFlow colorMode="dark" 
                            nodes={nodes}
                            onNodesChange={onNodesChange}
                            edges={edges}
                            edgeTypes={{animatedSvg: AnimatedSVGEdge}}
                            onNodeClick={(e,node) => {workflowActionTrigger(e,node)}}
                            onEdgesChange={onEdgesChange}
                            onConnect={onConnect}
                            fitView
                        >
                            <Background />
                            <Controls />
                        </ReactFlow>
                    </div>
                    ) : (<NoDataPage displayText={'Lost in space, no workflows available'} />)}
                </div>
            )}
            <AlertComponent alertOpen={openSnackbar} message={keyMessage} type={errorType === 'error' ? 'error' : 'success'} closeAlert={() => setOpenSnackbar(false)} />
        </>
    )
}