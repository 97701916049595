import React, { useEffect, useRef, useState } from "react";
import {
  Typography, Button, Box, Grid, Paper, Container, useTheme, styled,
  Card,
  Modal,
} from "@mui/material";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import ExploreIcon from "@mui/icons-material/Explore";
import BarChartIcon from "@mui/icons-material/BarChart";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { PreLoader } from "./subcomponents/preloader";
import TutorialComponent from "./subcomponents/DemoTour";
import { assignTagsPOST, clientBucketIntegratePOST, completeMultipartUpload, getDatasetInformation, organizationDetails, startModelPOST, uploadDatasetFiles, uploadMultipartFiles, uploadPresignedURLFiles } from "../services/Endpoints";
import { DemoDatasets } from "../constants/demoDatasets";
import { DatasetDetailsProps } from "../common/types";
import { GetThresholdDetails } from "./subcomponents/CalculateThresholdDetails";
import AlertComponent from "./subcomponents/AlertComponent";
import { iconStyle } from "../constants/AnalyticsPageConst";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";
import CodeIcon from '@mui/icons-material/Code';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PublishIcon from '@mui/icons-material/Publish';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Swal from "sweetalert2";
import JSZip from 'jszip';
import { buttonColor } from "../constants/Workflow";

const DatasetDetails: React.FC<DatasetDetailsProps> = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [loader , setLoader] = useState<boolean>(true);
  const [keyMessage , setKeyMessage] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorType , setErrorType] = useState<string>("");
  const [calculateThreshold,setCalculateThreshold] = useState<boolean>(false);
  const [downloadState , setDownloadState] = useState<boolean>(false);
  const [uploadFile , setUploadFile] = useState<boolean>(false);
  const [folderName , setFolderName] = useState<string>("");
  const [files, setFiles] = useState<any>([]);
  const [uploadDisabled , setUploadDisabled] = useState<boolean>(true);
  const [uploadClicked , setUploadClicked] = useState<boolean>(false);
  const [uploadType , setUploadType] = useState<string>("");
  const [hasImages , setHasImages] = useState<number>(0);
  const [hasAnnotations ,setHasAnnotations] = useState<number>(0);
  const [triggerModelClicked , setTriggerModelClicked] = useState<boolean>(false);
  let tags: any = [];
  const part: any = [];

  let triggerClicked = 0;
  let folderRef:any = useRef(null);
  let jsonRef:any = useRef(null);
  useEffect(() => {
    if (!location.state) {
      navigate("/dashboard");
    }
    if(location.state)
    {
      if(modelState === "running")
      {
        alert("Model is still processing your data , explore dataset is disabled for now");
        setTriggerModelClicked(true);
      }
      else if(auditState==="running")
      {
        alert("Model is still processing your data , data audits is disabled for now")
      }
    }
    fetchDatasetInfo();
  }, [location, navigate]);

  useEffect(() => {
    if(files.length > 0)
    {
      uploadType !=="json" ? zipFiles() : setFolderName(files[0].name);
      setUploadFile(true);
    }
  },[files])

  const handleFolderUploadClick = () => {
    if (folderRef.current) {
      folderRef.current.value = "";
      folderRef.current.click();
      setUploadType("zip");
      setFolderName("");
      setFiles([]);
    }
  };

  const splitFileToChunks = (file: File) => {
    const MAX_CHUNK_SIZE = 50 * 1024 * 1024; 
    const chunks = [];
    let start = 0;
    while (start < file.size) {
      const chunk = file.slice(start, start + MAX_CHUNK_SIZE);
      const chunkFile = new File([chunk], file.name, {
        type: file.type,
        lastModified: file.lastModified,
      });
  
      chunks.push(chunkFile);
  
      start += MAX_CHUNK_SIZE;
    }
    return chunks;
  }

  const uploadPresignedURL = async (file : File,url : string, type : string) => {
    const uploadFileData = await uploadPresignedURLFiles(file, url);
    if (uploadFileData.status === 200) {
      if(type !== "multipart")
      {
        setKeyMessage(`Uploaded data, ${datasetName}`);
        setErrorType("success");
        setOpenSnackbar(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      else {
        const eTagValue = uploadFileData.headers.get('Etag');
        part.push({"ETag" : eTagValue , PartNumber : part.length+1})
      }
    }
    else {
      setKeyMessage("An error occured");
      setErrorType("error");
      setOpenSnackbar(true);
    }
  }

  const handleJsonUploadClick = () => {
    if (hasImages === 0) {
      setKeyMessage("Please upload images before uploading annotations");
      setErrorType("error");
      setOpenSnackbar(true);
    }
    else {
      if (jsonRef.current) {
        jsonRef.current.value = "";
        jsonRef.current.click();
        setUploadType("json");
        setFolderName("");
        setFiles([]);
        setUploadDisabled(false);
      }
    }
  };

  const zipFiles = async () => {
    const zip = new JSZip();
    setFolderName(files[0].webkitRelativePath.split('/')[0]);
    files.forEach((file:File) => {
      zip.file(file.webkitRelativePath, file);
    });
    const content = await zip.generateAsync({ type: 'blob' });
    setUploadDisabled(false);
    setFiles(content);
  };

  const fetchDatasetInfo = async () => {
    try{
      const response = await getDatasetInformation(datasetId)
      const data = await response.json();
      setHasAnnotations(data.hasannotations);
      setHasImages(data.hasimages);
      setDownloadState(data.result_json);
      setLoader(false);
    }
    catch(err:any)
    {
      setKeyMessage(err.message);
      setErrorType("error");
      setOpenSnackbar(true);
      setLoader(false);
    }
  }

  const handleFolderUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;
    const fileArray = Array.from(files);
    setFiles(fileArray);
  };

  const assignLabels = () => {
    if (hasImages === 0) {
      setKeyMessage("Please upload images before assigning labels");
      setErrorType("error");
      setOpenSnackbar(true);
    }
    else
    {
      Swal.fire({
        title: "Let's tag your dataset!",
        background:'black',
        color:'white',
        html: `
          <div class="handleS3IntegrationDiv">
            <label for="labelName">Labels</label>
            <input class="inputText" type="text" id="labelName" placeholder="Label Name" value="">
            <div id="tagContainer" style="margin-top: 10px;"></div>
            <label for="labelName">Note : You can only process upto ten classes at a time . 
              To add a new label click enter </label>
          </div>
        `,
        showConfirmButton:true,
        confirmButtonText:'Add Label',
        confirmButtonColor:'#f79009',
        showCancelButton: true,
        showCloseButton: true,
        didOpen: () => {
          const input = document.getElementById('labelName') as HTMLInputElement;
          const tagContainer = document.getElementById('tagContainer') as HTMLDivElement;
  
          input!==null && input.addEventListener('keydown', function (event) {
            if (event.key === 'Enter' && input.value.trim() !== '' && !input.value.includes(' ')) {
              if (tags.length < 10) {
                addTag(input.value.trim());
                input.value = '';
              } else {
                Swal.showValidationMessage('You can only add up to 10 tags.');
              }
            }
          });
  
          const addTag = (tagName:string) => {
            tags.push(tagName);
            renderTags();
          };
  
          const removeTag = (index:number) => {
            tags.splice(index, 1);
            renderTags();
          };
  
          const renderTags = () => {
            tagContainer.innerHTML = '';
  
            tags.forEach((tag:string, index:number) => {
              const tagElement = document.createElement('div');
              tagElement.style.display = 'inline-block';
              tagElement.style.padding = '5px 10px';
              tagElement.style.margin = '5px';
              tagElement.style.background = '#f79009';
              tagElement.style.color = 'white';
              tagElement.style.borderRadius = '20px';
              tagElement.style.cursor = 'default';
              tagElement.innerHTML = `
            ${tag} 
            <span style="margin-left: 8px; cursor: pointer;" id="removeTag-${index}">X</span>
          `;
  
              tagContainer.appendChild(tagElement);
              document.getElementById(`removeTag-${index}`)?.addEventListener('click', () => {
                removeTag(index);
              });
            });
          };
        },
        willClose: () => {
        }
      }).then(async (result) => {
        if(result.isConfirmed)
        {
          const payload=JSON.stringify({
            id : datasetId,
            classes : tags
          });
          await assignTagsPOST(payload,'POST')
          .then((response) => response.json())
          .then((data) => {
            setKeyMessage(data.message);
            setErrorType('success');
            setOpenSnackbar(true);
            window.location.reload();
          })
          .catch((err) => {
            setKeyMessage(`Something went wrong , please try again`);
            setErrorType('error');
            setOpenSnackbar(true);
          })
        } 
      });
    }
  }

  const handleS3BucketIntegration = () => {
    Swal.fire({
      title: "S3 Client Bucket Integration",
      background:'black',
      color:'white',
      html: `
        <div class="handleS3IntegrationDiv">
          <input class="inputText" type="text" id="datasetName" placeholder="Dataset Name">
          <input class="inputText" type="text" id="clientBucket" placeholder="Client Bucket">
          <input class="inputText" type="text" id="sourceKey" placeholder="Source Key">
          <div class="taskTypeDiv">
          <label for="options">Task Type :</label>
          <select id="taskType" name="taskType">
              <option value="classification">Classification</option>
              <option value="detection">Detection</option>
          </select>
          </div>
        </div>
      `,
      showConfirmButton:true,
      confirmButtonText:'Integrate your dataset',
      confirmButtonColor:'#f79009',
      showCancelButton: true,
      showCloseButton: true,
      didOpen: () => {
      },
      willClose: () => {
      }
    }).then(async (result) => {
      if(result.isConfirmed)
      {
        const datasetName = (document.getElementById('datasetName') as HTMLInputElement).value;
        const clientBucket = (document.getElementById('clientBucket') as HTMLInputElement).value;
        const sourceKey = (document.getElementById('sourceKey') as HTMLInputElement).value;
        const taskType = (document.getElementById('taskType') as HTMLInputElement).value;
        const payload=JSON.stringify({
          "dataset_name" : datasetName,
          "client_bucket" : clientBucket,
          "source_key" : sourceKey,
          "version" : '1.0',
          "task" : taskType
        });
        await clientBucketIntegratePOST(payload,'POST')
        .then((response) => response.json())
        .then((data) => {
          setKeyMessage(data.message);
          setErrorType('success');
          setOpenSnackbar(true);
          window.location.reload();
        })
        .catch((err) => {
          setKeyMessage(`Something went wrong , please try again`);
          setErrorType('error');
          setOpenSnackbar(true);
        })
      } 
    });
  }

  const displayOrganizationDetails = async () => {
    try{
      const payload = JSON.stringify({
        datasetId : datasetId,
        "result_json" : true
      });
      const response = await organizationDetails(payload,"POST")
      const data = await response.json()
      window.location.href=data.url[0];
    }
    catch(err:any)
    {
      setKeyMessage(err.message);
      setErrorType("error");
      setOpenSnackbar(true);
      setLoader(false);
    }
  }
  
  const triggerModel = () => {
    const checkTrigger = ++triggerClicked;
    setTriggerModelClicked(true);
    if (checkTrigger <= 1) {
      const payload = JSON.stringify({
        "datasetId": `${datasetId}`
      });
      startModelPOST(payload, "POST")
        .then((response) => response.json())
        .then((data) => {
          setTimeout(() => {
            navigate("/dashboard")
          }, 3000)
        })
        .catch((err) => {
          setKeyMessage(err.message);
          setErrorType("error");
          setOpenSnackbar(true);
          setTriggerModelClicked(false);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }

  if (!location.state) {
    return null;
  }

  const { datasetName,datasetId, createdDate, createdBy, updatedDate, imageUrl,dataQualityScore,completenessScore,consistencyScore,modelState,scoreState,auditState,task , imagesCount ,graphMethod } = location.state;

  const SnapshotPaper = styled(Paper)({
    backgroundColor: "rgb(67, 69, 74)",
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  });

  const ActionButton = styled(Button)({
    backgroundColor: "#F78005",
    color: theme.palette.common.white,
    marginTop: theme.spacing(2),
    "&:hover": {
      backgroundColor: "#DA830C",
    },
  });

  const handleUpload = async (file:any) => {
    setUploadClicked(true);
    const maxFileSize = 500 * 1024 * 1024;
    if(file.size > maxFileSize)
    {
      setKeyMessage("File Size is over 500 MB");
      setErrorType("error");
      setOpenSnackbar(true);
    }
    const payload = JSON.stringify({
      dataSetId: datasetId,
      fileSize: file.size,
      contentType: file.type,
      filename: uploadType === "json" ? folderName : `${folderName}.zip`,
      fileType: uploadType === "json" ? "METADATA" : "IMAGES"
    })
    try {
      setKeyMessage("Uploading data , please do not refresh the page");
      setErrorType("success");
      setOpenSnackbar(true);
      const response = await uploadDatasetFiles(payload , "POST");
      const data = await response.json();
      if(uploadType === "json")
      {
        if (response.status === 200) {
          setKeyMessage(`Uploaded data, ${datasetName}`);
          setErrorType("success");
          setOpenSnackbar(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }
      else {
        if (response.status === 200) {
          if(data.method === "MULTIPART")
          {
            const chunks = splitFileToChunks(file);
            for (let index = 0; index < chunks.length; index++) {
              const chunk = chunks[index];
              
              const payload = JSON.stringify({
                dataSetId: datasetId,
                filename: `${folderName}.zip`,
                partNumber: index + 1,
                uploadId: data.uploadId,
                fileType: "IMAGES"
              });
            
              const uploadChunks: any = await uploadMultipartFiles(payload, "POST");
              const chunkResponseData = await uploadChunks.json();
              setKeyMessage(`Uploading Part ${index+1} / ${chunks.length}`);
              setErrorType("success")
              setOpenSnackbar(true);
              await uploadPresignedURL(chunk, chunkResponseData.url, "multipart");
            }
            
            const payload = JSON.stringify({
              "dataSetId": datasetId,
              "filename": `${folderName}.zip`,
              "parts": part,
              "uploadId": data.uploadId,
              "fileType": "IMAGES"
            })
            const completeMultipart = await completeMultipartUpload(payload , "POST");
            const completeUploadData = await completeMultipart.json();
            if(completeMultipart.status === 200)
            {
              setKeyMessage(`Uploaded data, ${datasetName}`);
              setErrorType("success");
              setOpenSnackbar(true);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
            else
            {
              setKeyMessage(completeUploadData.errors[0].message);
              setErrorType("error");
              setOpenSnackbar(true);
            }
          }
          else
          {
            await uploadPresignedURL(file , data.url,"singlepart");
          }
        }
        else {
          setKeyMessage(data.errors[0].message);
          setErrorType("error");
          setOpenSnackbar(true);
        }
      }
    }
    catch (err: any) {
      setKeyMessage(err.message);
      setErrorType("error");
      setOpenSnackbar(true);
    }
    finally {
      setUploadFile(false);
      setUploadClicked(false);
    }
  }
  

  return (
   <>
      {loader ? (<><PreLoader preloaderCheck={loader} /></>) : (<div style={{ minHeight: "70vh" }}>
        <TutorialComponent type="datasetDetails" />
        <Box sx={{ flexGrow: 1, margin: "20px", borderRadius: "10px" }}>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4, padding: "20px" }}>
            <Typography variant="h4" gutterBottom component="div" sx={{ color: "white" }}>
              {datasetName.toUpperCase()}
            </Typography>
            <Grid container spacing={3} sx={{ alignItems: "center" }}>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    bgcolor: "rgb(67, 69, 74)",
                    p: 2,
                    borderRadius: 2,
                    display: "flex",
                    justifyContent: "center",
                    marginTop:"20px"
                  }}
                >
                  <img src={imageUrl === undefined ? "https://i.seadn.io/gae/OGpebYaykwlc8Tbk-oGxtxuv8HysLYKqw-FurtYql2UBd_q_-ENAwDY82PkbNB68aTkCINn6tOhpA8pF5SAewC2auZ_44Q77PcOo870?auto=format&dpr=1&w=3840" : imageUrl} alt="Dataset" style={{ width: "100%", height: "340px", borderRadius: theme.shape.borderRadius }} />
                </Box>
              </Grid>

              {location.state && <Grid item xs={12} sm={6}>|
                <Box sx={{ padding: "10px" }}>

                  {hasImages > 0 ? (
                    <>
                      <div className="actionButtonStyle">
                        <Link to="/viewimages" state={{ datasetName: datasetName, datasetId: datasetId, auditIssues: null }}>
                          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", height: "60px" }}>
                            <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                              <ImageSearchIcon sx={iconStyle} />
                              <Typography variant="h6" sx={iconStyle}>VIEW IMAGES</Typography>
                            </div>
                            <ChevronRightIcon sx={iconStyle} />
                          </div>
                        </Link>
                      </div>


                      <Divider component="div" sx={{ color: "grey" }} />

                      <>
                        {(!DemoDatasets.includes(datasetId) && (modelState === "none")) ? (
                          <div className={!triggerModelClicked ? "actionButtonStyle" : "disabledActionButton"}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", height: "60px" , cursor:!triggerModelClicked ? "pointer" : "not-allowed" }} onClick={() => !triggerModelClicked && triggerModel()}>
                              <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                                <PlayCircleFilledWhiteIcon sx={iconStyle} />
                                <Typography variant="h6" sx={iconStyle}>GENERATE INSIGHTS</Typography>
                              </div>
                              <ChevronRightIcon sx={iconStyle} />
                            </div>
                          </div>
                        ) : modelState !== "running" ? (
                          <div className="actionButtonStyle">
                            <Link to={modelState !== "running" ? "/exploredataset" : "/dashboard"} className="actionButtonStyle" state={{ datasetId: datasetId, datasetName: datasetName, graphMethod: graphMethod }}>

                              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", height: "60px" }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                                  <ExploreIcon sx={iconStyle} />
                                  <Typography variant="h6" sx={iconStyle}>EXPLORE DATASET</Typography>
                                </div>
                                <ChevronRightIcon sx={iconStyle} />
                              </div>

                            </Link>
                          </div>
                        ) : (
                              <div className="disabledActionButton">
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", height: "60px" }}>
                                  <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                                    <ExploreIcon sx={iconStyle} />
                                    <Typography variant="h6" sx={iconStyle}>EXPLORE DATASET</Typography>
                                  </div>
                                  <ChevronRightIcon sx={iconStyle} />
                                </div>
                              </div>
                        )}
                      </>

                      <Divider component="div" sx={{ color: "grey" }} />

                      <div className="actionButtonStyle">
                        <Link to="/analytics" state={{ datasetName: datasetName, datasetId: datasetId, dataQualityScore: dataQualityScore, dataCompletenessScore: completenessScore, dataConsistencyScore: consistencyScore, scoreState: scoreState }}>
                          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", height: "60px" }}>
                            <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                              <BarChartIcon sx={iconStyle} />
                              <Typography variant="h6" sx={iconStyle}>ANALYTICS</Typography>
                            </div>
                            <ChevronRightIcon sx={iconStyle} />
                          </div>
                        </Link>
                      </div>

                      <Divider component="div" sx={{ color: "grey" }} />

                      {(!DemoDatasets.includes(datasetId) && (auditState === "none")) ? (
                        <div className="actionButtonStyle">
                          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", height: "60px" }} onClick={() => modelState === "success" && setCalculateThreshold(true)}>
                            <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                              <DataThresholdingIcon sx={iconStyle} />
                              <Typography variant="h6" sx={iconStyle}>DATA AUDIT</Typography>
                            </div>
                            <ChevronRightIcon sx={iconStyle} />
                          </div>
                        </div>
                      ) : auditState !== "running" ? (
                        <div className="actionButtonStyle">
                          <Link to={auditState !== "running" ? "/dataaudit" : "/"} state={{ datasetName: datasetName, datasetId: datasetId }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", height: "60px" }}>
                              <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                                <DataThresholdingIcon sx={iconStyle} />
                                <Typography variant="h6" sx={iconStyle}>DATA AUDIT</Typography>
                              </div>
                              <ChevronRightIcon sx={iconStyle} />
                            </div>
                          </Link>
                        </div>
                      ) : (
                        <div className="disabledActionButton">
                          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", height: "60px" }}>
                            <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                              <DataThresholdingIcon sx={iconStyle} />
                              <Typography variant="h6" sx={iconStyle}>DATA AUDIT</Typography>
                            </div>
                            <ChevronRightIcon sx={iconStyle} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="actionButtonStyle" onClick={() => {handleFolderUploadClick()}}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", height: "60px" }}>
                          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                            <PermMediaIcon sx={iconStyle} />
                            <Typography variant="h6" sx={iconStyle}>Upload Images from Folder</Typography>
                          </div>
                          <input
                            type="file"
                            webkitdirectory="true"
                            accept=".zip,application/zip"
                            onChange={handleFolderUpload}
                            ref={folderRef}
                            hidden
                          />
                          <ChevronRightIcon sx={iconStyle} />
                        </div>
                      </div>

                      {hasAnnotations === 0 && (
                    <>
                      <div className="actionButtonStyle" onClick={() => {handleJsonUploadClick()}}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", height: "60px" }}>
                          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                            <PublishIcon sx={iconStyle} />
                            <Typography variant="h6" sx={iconStyle}>Upload Annotation</Typography>
                          </div>
                                <input
                                  type="file"
                                  accept=".json,application/json"
                                  onChange={handleFolderUpload}
                                  ref={jsonRef}
                                  hidden
                                />
                          <ChevronRightIcon sx={iconStyle} />
                        </div>
                      </div>
                      <div className="disabledActionButton" 
                        onClick={() => 
                        // assignLabels()
                        {}
                        }>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", height: "60px" }}>
                          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                            <AssignmentTurnedInIcon sx={iconStyle} />
                            <Typography variant="h6" sx={iconStyle}>Assign Labels</Typography>
                          </div>
                          <ChevronRightIcon sx={iconStyle} />
                        </div>
                      </div>
                    </>
                  )}

                      <div className="actionButtonStyle" onClick={handleS3BucketIntegration}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", height: "60px" }}>
                          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                            <CloudDownloadIcon sx={iconStyle} />
                            <Typography variant="h6" sx={iconStyle}>Import from AWS S3</Typography>
                          </div>
                          <ChevronRightIcon sx={iconStyle} />
                        </div>
                      </div>

                      <div className="actionButtonStyle" onClick={() => {
                        setKeyMessage('Please use the CLI tool to upload datasets');
                        setErrorType('success');
                        setOpenSnackbar(true);
                      }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", height: "60px" }}>
                          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                            <CodeIcon sx={iconStyle} />
                            <Typography variant="h6" sx={iconStyle}>Upload via CLI</Typography>
                          </div>
                          <ChevronRightIcon sx={iconStyle} />
                        </div>
                      </div>
                    </>
                  )}

                  <Card sx={{ background: "black", color: "white", marginTop: "10px" }}>
                    <>
                      <div className="snapshotDiv">
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: "700" }}>
                          Snapshot
                        </Typography>
                        <>
                          {task &&
                            (
                              <div className="taskDiv">
                                <LocalOfferIcon sx={{ fontSize: "medium" }} />
                                <Typography variant="body2" gutterBottom>
                                  {task.split(" ").map((cate: string) => cate.slice(0, 1).toUpperCase() + cate.slice(1)).join("")}
                                </Typography>
                              </div>
                            )}
                        </>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "20px" }}>
                        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                          <Typography variant="body2" sx={{ fontWeight: "700" }}>Created at</Typography>
                          <Typography variant="body2">{new Date(createdDate).toLocaleString()}</Typography>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                          <Typography variant="body2" sx={{ fontWeight: "700" }}>Updated at</Typography>
                          <Typography variant="body2">{new Date(updatedDate).toLocaleString()}</Typography>
                        </div>
                      </div>
                    </>
                  </Card>
                </Box>
              </Grid>}
              {calculateThreshold && (<GetThresholdDetails datasetId={datasetId} openLoader={() => setLoader(true)} closeLoader={() => setLoader(false)} closePopup={() => setCalculateThreshold(false)} />)}
            </Grid>
          </Container>
        </Box>
        <Modal
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={uploadFile}
          sx={{ padding: "30px", alignItems: "center", justifyContent: "center" }}
          onClose={() => setUploadFile(false)}
        >
          {uploadClicked ? <><PreLoader preloaderCheck={uploadClicked} /></> : <>
            <div className="uploadFileDiv">
              <div className="uploadSection">
                  <Box mt={2}>
                    <Typography variant="subtitle1" sx={{color:"#f79009"}}>{uploadDisabled ? "Extracting files and compressing to ZIP , Please wait..." : `${folderName}.zip`}</Typography>
                  </Box>
              </div>
              <div className="fileButtons">
                <Button className='primary' onClick={() => setUploadFile(false)} sx={buttonColor}>Close</Button>
                <Button
                  className='primary'
                  onClick={
                    () => handleUpload(uploadType === "json" ? files[0] : files)}
                  sx={buttonColor}
                  disabled={uploadDisabled}
                >
                  Upload
                </Button>
              </div>
            </div>
          </>}
        </Modal>
      </div>)}
    <AlertComponent message={keyMessage} type={errorType === "error" ? "error" : "success"} alertOpen={openSnackbar} closeAlert={() => setOpenSnackbar(false)}/>
   </>
  );
};

export default DatasetDetails;
