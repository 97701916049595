import React, { useState , SyntheticEvent, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { AlertComponentProps } from '../../common/types';

const AlertComponent: React.FC<AlertComponentProps> = ({ alertOpen, message,  type , closeAlert }) => {
    const [openSnackBar,setOpenSnackBar] = useState<boolean>(false);
    const checkUpload = message.includes("Uploading");
    useEffect(() => {
        setOpenSnackBar(alertOpen); 
      }, [alertOpen]);
    const handleCloseSnackbar = (
        event: SyntheticEvent<Element, Event> | Event,
        reason?: string
      ) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackBar(false);
        closeAlert();
      };
  return (
    <Snackbar
      open={openSnackBar}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      anchorOrigin={
        checkUpload ? {vertical: 'bottom', horizontal: 'right'} :
        { vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={handleCloseSnackbar} severity={type} sx={{ width: '100%', textAlign: 'center' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertComponent;